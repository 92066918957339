import React from 'react'
import About from './components/About'
import Contact from './components/Contact'
import Landing from './components/Landing'  
import Services from './components/Services'
import Benefits from './components/Benefits'
import Header from './components/Header'
import Footer from './components/Footer'
import Team from './components/Team'
import TrustedBy from './components/TrustedBy'

const App = () => {
  return (
    <div className='App'>
    <Header/>
      <About/>
      <Services />
      <Benefits />
      <Team/>
      <TrustedBy/>
      <Contact />
      <Footer/> 
    
      {/* <Landing/> */}
    </div>
  )
}

export default App
