import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Card,
  CardImg,
  Row,
  Col,
  CardTitle,
  CardText,
} from "reactstrap";
import StingImage from "../images/Sting.png";
import TrustedAIImage from "../images/Trusted_AI.png";

const TrustedBy = () => {
  const [textInView, setTextInView] = useState(false);

  const refText = useRef();

  useEffect(() => {
    const textObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTextInView(true);
          textObserver.disconnect(); // Stop observing once animation is triggered
        }
      },
      { threshold: 0.1 }
    );

    if (refText.current) {
      textObserver.observe(refText.current);
    }

    return () => {
      if (refText.current) {
        textObserver.unobserve(refText.current);
      }
    };
  }, [refText]);

  return (
    <div className="team">
      <CardTitle className="section-title">TRUSTED BY</CardTitle>
      <div ref={refText} className={`title ${textInView ? "slide-in" : ""}`}>
        <CardText>Proud to Partner with Innovators!</CardText>
      </div>

      <Row className="justify-content-center" style={{ marginTop: "30px" }}>
        <Col sm="3">
          <Card body style={{ border: "none" }}>
            <CardImg
              top
              src={StingImage}
              alt="Card image"
              style={{ width: "100px" }}
            />
          </Card>
        </Col>
        <Col sm="3">
          <Card body style={{ border: "none" }}>
            <CardImg
              top
              src={TrustedAIImage}
              alt="Card image"
              style={{ width: "120px" }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TrustedBy;
