import { GrLinkedin } from "react-icons/gr";
const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: "#7E1757",
        padding: "10px",
        color: "#f2f2f2",
        textAlign: "center",
        position: "relative", // Fixed positioning to stick to the bottom
        bottom: "0",
        width: "100%",
      }}
    >
     <div style={{ 
        display: "flex", 
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "0 20px"
      }}>
        <p style={{ margin: 0 }}>&copy; 2024 ATO Energy. All rights reserved.</p>
        <a href="https://www.linkedin.com/company/ato-energy/posts/?feedView=all">
          <GrLinkedin size={25} style={{ color: "#ffffff" }} />
        </a>
      </div> 
    </footer>
  );
};

export default Footer;
