import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, CardTitle, CardText } from "reactstrap";

import teamData from "./teamData.json"; // Renamed the variable to teamData
import TeamCard from "./TeamCard";
import advisorData from "./advisorData.json"; // Renamed the variable to advisorData

const Team = () => {
  const [teamInView, setTeamInView] = useState(false);
  const [advisorInView, setAdvisorInView] = useState(false);

  const refTeam = useRef();
  const refAdvisor = useRef();

  useEffect(() => {
    const teamObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTeamInView(true);
          teamObserver.disconnect(); // Stop observing once animation is triggered
        }
      },
      { threshold: 0.1 }
    );

    if (refTeam.current) {
      teamObserver.observe(refTeam.current);
    }

    return () => {
      if (refTeam.current) {
        teamObserver.unobserve(refTeam.current);
      }
    };
  }, [refTeam]);

  useEffect(() => {
    const advisorObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setAdvisorInView(true);
          advisorObserver.disconnect(); // Stop observing once animation is triggered
        }
      },
      { threshold: 0.1 }
    );

    if (refAdvisor.current) {
      advisorObserver.observe(refAdvisor.current);
    }

    return () => {
      if (refAdvisor.current) {
        advisorObserver.unobserve(refAdvisor.current);
      }
    };
  }, [refAdvisor]);

  return (
    <div className="team">
      <CardTitle className="section-title">OUR TEAM</CardTitle>
      <div ref={refTeam} className={`title ${teamInView ? "slide-in" : ""}`}>
        <CardText>We Are a Dynamic Team of Creative People!</CardText>
      </div>

      <Row className="justify-content-center">
        {teamData.map((teamMember) => (
          <Col
            xs="12"
            sm="6"
            md="6"
            lg="3"
            className="mb-5"
            key={teamMember.id}
          >
            <TeamCard data={teamMember} />
          </Col>
        ))}
      </Row>

      <div
        ref={refAdvisor}
        className={`title ${advisorInView ? "slide-in" : ""}`}
      >
        <CardText>Our advisors!</CardText>
      </div>

      <Row className="justify-content-center">
        {advisorData.map((teamMember) => (
          <Col
            xs="12"
            sm="6"
            md="4"
            lg="3"
            className="mb-5"
            key={teamMember.id}
          >
            <TeamCard data={teamMember} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Team;
